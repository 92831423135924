import React, {useState, useEffect, useContext } from 'react'
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { Card, Row } from '../../shared/utils'
import MonitoringSubTopicCard from './MonitoringSubTopicCard'
import { useLocation } from 'react-router-dom'
import { jwtDecode } from "jwt-decode";
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { getCmpTopic, createCmpTopic, updateCmpTopic, createNotification } from '../../../API/Api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TokenContext } from '../../../App';

const _CreateUpdateComplianceTopicForm = () => {
      const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(encodedAccessToken)
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const topicId = queryParams.get('topicId'); 
  const navigate = useNavigate()

  let title;
  if (topicId) { title = "Update Topic" } 


  const subTopicBody = (numberOfSubTopics) => ({
    subTopicName: '',
    subTopicId: uuidv4(),
    questions: [
      { question: '', questionId: uuidv4(), frequency: 'Daily', regulatoryReference: '', internalReference: `Q-1 S-${numberOfSubTopics} D-${DateTime.now().toFormat('dd-MM-yyyy')}`, responses: { } } // 'mongoDB converts empty object to array hence this placeholder'
    ]
  });
  

  const { register, handleSubmit, control, reset, watch, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      topicName: '',
      subTopics: [subTopicBody(1)]
    }
  });
  
  const numberOfSubTopics = watch('subTopics').length + 1;

 
  useEffect(() => {
    const fetchData = async () => {
      if (!topicId) { return } 
      const cmpTopicData = await getCmpTopic( topicId)

      // format saved 'dd-mm-yyyy' date to 'yyyy-mm-dd' to fit default <input maxLength={800} type='date'> format
      cmpTopicData.data.subTopics.map((subTopic, subIndex) => {
        subTopic.questions.map( (question, qnIndex) => {
          if (question.nextRenewalDate) {
            cmpTopicData.data.subTopics[subIndex].questions[qnIndex].nextRenewalDate = DateTime.fromFormat(cmpTopicData.data.subTopics[subIndex].questions[qnIndex].nextRenewalDate, 'dd-MM-yyyy').toFormat('yyyy-MM-dd'); 
          }
        })
      });

      reset(cmpTopicData.data); 
    };
    fetchData();
  }, [reset]);


  const { fields, append, remove } = useFieldArray({
    control,
    name: "subTopics"
  });

  const onSubmit = async (cmpNewTopicData) => {
    try {

      // format saved 'dd-mm-yyyy' date to 'yyyy-mm-dd' to fit default <input maxLength={800} type='date'> format
      cmpNewTopicData.subTopics.map((subTopic, subIndex) => {
        subTopic.questions.map( (question, qnIndex) => {
          const date = DateTime.fromISO(question?.nextRenewalDate) || '';   //recieve default yyyy-mm-dd value from html input
          const formattedDate = date.isValid ? date.toFormat('dd-MM-yyyy') : ''; // if no date provided, use empty string as value, else convert to dd-mm-yyyy
          cmpNewTopicData.subTopics[subIndex].questions[qnIndex].nextRenewalDate = formattedDate

        })
      });

      if (!topicId) { 
        await createCmpTopic({
          ...cmpNewTopicData, 
          totalQuestions: cmpNewTopicData?.subTopics.reduce((total, subtopic) => total + (subtopic.questions?.length || 0), 0),
          creatorEmail : decodedToken.email,
          createdAt: DateTime.now().toFormat('dd-MM-yyyy') })

          createNotification({
            type: 'CMP',
            text: `A new form, ${cmpNewTopicData.topicName}, has been created`,
            date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
        })

        toast.success("Topic created!") 
       } else {
        await updateCmpTopic({
          ...cmpNewTopicData, 
          totalQuestions: cmpNewTopicData?.subTopics.reduce((total, subtopic) => total + (subtopic.questions?.length || 0), 0),
          createdAt: DateTime.now().toFormat('dd-MM-yyyy') 
        },
          topicId)
        toast.success("Topic updated!") 
       }
      navigate('/compliance-monitoring/monitoring')
    } catch(e) {
      toast.error("Something went wrong...")
    }
  }



  return (
      <Card>
        <div className='flex justify '>
          <strong>  {title || 'Create Topic'} </strong>
          {/* <p className='border px-2 py-1 bg-border-2 ml-5 cursor-pointer hover:bg-gray-300 bg-gray-200'> Go back </p> */}
          { 
          title !== "Update Topic" && 
          <div>
            {/* <a href='/' className='mx-2'> Download Template (not ready yet) </a> */}
            {/* <input maxLength={800} type="file"  name="companyConstitution" className='border'/> */}
            {/* for this template, as long the resultant JSON match the save to DB format then ok alr, upload and navigate back home. */}


            {/* <input maxLength={800} type="file"  name="companyConstitution" onChange={(e) => handleFileUpload(e, 'CompanyConstitutionLink')} className='border'/> */}
            {/* {'formData?.companyConstitution' && ( <a href={'formData?.companyConstitution'} className='text-blue-600 mx-2'> uploadedfile </a> )} */}
            {/* {'formData?.companyConstitution' && ( <a href={'formData?.companyConstitution'} className='text-blue-600 mr-2'> {formData?.companyConstitution.split('/').slice(-1)[0]} </a> )} */}
          </div>
          }
       </div>


        <form onSubmit={handleSubmit(onSubmit)}> 
          <hr className='mt-4 mb-2'/>
          <strong className='underline'> Topic Name: </strong>
          <input maxLength={800} {...register('topicName')}  placeholder="Enter Topic Name" className="mb-4 w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />

          {fields.map((field, subTopicIndex) => (
            <MonitoringSubTopicCard watch={watch} key={field.id} nestIndex={subTopicIndex} control={control} register={register} removeSubTopic={remove} />
          ))}

          <div className='my-1'> 
            <span onClick={() =>  append(subTopicBody(numberOfSubTopics))} className=' hover:bg-gray-500 bg-gray-300 border-2  p-1 px-2 cursor-pointer'> Add Sub Topic </span>
          </div>


          <hr className='my-5'/>
          <div className='my-4 flex justify-end'> 
            <button type='submit' className='  hover:bg-green-500 border-2 p-1 mt-3 rounded-md bg-green-400 px-3'> {title || 'Create Topic'} </button>
          </div>
        </form>
      </Card>
  )
}


export default _CreateUpdateComplianceTopicForm