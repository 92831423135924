import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { useForm, SubmitHandler } from 'react-hook-form';
import { getCmpTopic, createNotification, getCmpAllTopics, createCmpForm, getCmpForm, updateCmpForm } from '../../../API/Api';
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { DateTime } from 'luxon';
import { jwtDecode } from 'jwt-decode';
import { TokenContext } from '../../../App'

const AddEditNewCmpFormModal = ({handleCloseModal, action='Create', setLoading, cmpFormId}) => {
      const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(encodedAccessToken)

  const [allCmpTopics, setAllCmpTopics] = useState([])

  const monitoringFormSchema = z.object({
    formName: z.string().min(1, { message: "Form Name must be at least 1 characters long" }),
    topicNameIdQuestions: z.string().refine(value => value !== "1", { message: "Please select a Topic" }),
    startDate: z.string().date({ message: "Please select start date" }),
    expiryDate: z.string().date({ message: "Please select expiry date" }),
  }).refine((data) => {
    const start = new Date(data.startDate);
    const expiry = new Date(data.expiryDate);
    return expiry > start;
  }, {
    message: "Expiry date must be after the start date",
    path: ['expiryDate'], 
  });


  const { register, handleSubmit, reset, watch, formState: {errors, isSubmitting } } = useForm({
    defaultValues: { 
      formName: '', topicNameIdQuestions: '', startDate: '', expiryDate: '', lastActivity: ''
    },
    resolver: zodResolver(monitoringFormSchema)
  });

  useEffect(() => {
    const fetchData = async () => {
      const cmpAllTopicData = await getCmpAllTopics()
      setAllCmpTopics(cmpAllTopicData.data); 
      let cmpFormData = await getCmpForm(cmpFormId)
      cmpFormData = cmpFormData.data
      
      cmpFormData.startDate = DateTime.fromFormat(cmpFormData.startDate, 'dd-MM-yyyy').toFormat('yyyy-MM-dd');
      cmpFormData.expiryDate = DateTime.fromFormat(cmpFormData.expiryDate, 'dd-MM-yyyy').toFormat('yyyy-MM-dd');

      // <input maxLength={800} type='date' saves it as yyyy-mm-dd and needs this format to show the value.
      // NEXT TIME, LEAVE IT AS yyyy-mm-dd if it comes from html input. only change format for display data else too messy keep convert.
      // save it as default new Date(), and do conversion before rendering, to keep all info
 
      reset(cmpFormData)
    };
    fetchData();
  }, [reset]);

  const onSubmit = async (newFormMonitoringData) => { 
      newFormMonitoringData.startDate = DateTime.fromISO(newFormMonitoringData.startDate).toFormat('dd-MM-yyyy');
      newFormMonitoringData.expiryDate = DateTime.fromISO(newFormMonitoringData.expiryDate).toFormat('dd-MM-yyyy');

      const [topicId, topicName, topicQuestions] = newFormMonitoringData.topicNameIdQuestions.split(',');
      delete newFormMonitoringData.topicNameIdQuestions

      if (action === 'Create') {
        let subTopics = await getCmpTopic(topicId)
        subTopics = subTopics.data.subTopics

        subTopics.map((subTopic, subIndex) => {
          subTopic.questions.map( (question, qnIndex) => {
            if (!question.nextRenewalDate && question.frequency !== 'N/a' ) {
              subTopics[subIndex].questions[qnIndex].nextRenewalDate = newFormMonitoringData.startDate 
            }  else if (question.frequency === 'N/a') {
              subTopics[subIndex].questions[qnIndex].nextRenewalDate = newFormMonitoringData.expiryDate
            }

            const today = DateTime.now().toFormat('dd-MM-yyyy')
            let dateBeforeRenewal =  DateTime.fromFormat(subTopics[subIndex].questions[qnIndex].nextRenewalDate, 'dd-MM-yyyy').minus({ days: 1 })
            const responseDate = today + ' to ' + dateBeforeRenewal.toFormat('dd-MM-yyyy') 

            subTopics[subIndex].questions[qnIndex].responses[responseDate] = {
              response: '', responseDate: '', fileUrl: '',
              richText:'', status: 'pending', comments: [], emailNotifications: []
            }
          })
        });

        const createMonitoringForm = {
          ...newFormMonitoringData,
          creatorEmail: decodedToken.email,
          topicId: topicId,
          topicName: topicName,
          totalQuestions: topicQuestions,
          pendingActionQn: topicQuestions,
          pendingReviewQn: 0,
          reviewedQn: 0, 
          lastActivity: DateTime.now().toFormat('dd-MM-yyyy'),
          subTopics: subTopics,
          archived: false
        }
        await createCmpForm(createMonitoringForm)
        createNotification({
          type: 'CMP', 
          text: `A new form, ${newFormMonitoringData.formName}, has been created`,
          date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
      })

      } else {
        const updateMonitoringForm = {
          _id: cmpFormId,
          formName: newFormMonitoringData.formName,
          startDate: newFormMonitoringData.startDate,
          expiryDate: newFormMonitoringData.expiryDate,
        }
        await updateCmpForm(updateMonitoringForm)
      }

    setLoading(true) // have to trigger re-fetch from DB cuz need the added objectId
    toast.success("Form created!")
    handleCloseModal()
  }
 
  return (
      <div className="sm:w-[60vw] md:w-[45vw] lg:w-[40vw] xl:w-[30vw] p-5">  {/* //-> viewport for different breakpoints */}
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <p className="text-sm font-medium text-gray-700"> Form Name </p>
                <input maxLength={800} {...register('formName')} placeholder="Enter Form Name" className="w-full p-1 border-b border-gray-300 focus:outline-none focus:border-green-500" />
                {errors?.formName && ( <div className="text-red-500">{errors?.formName?.message}</div> )}
              </div>
            <div>
            {action == 'Update' ? (
              <>
                {/* <span> Topic Name </span> */} 
                <input maxLength={800} {...register('topicNameIdQuestions')} type="hidden"/>
              </>
            ) : (
              <>
                <p className="block text-sm font-medium text-gray-700 mt-3"> Topic </p>
                <select {...register('topicNameIdQuestions')} className='w-full p-1 border-b border-gray-300 bg-white focus:outline-none focus:border-green-500'>
                  <option value={1}> - Select Topic - </option>
                  { allCmpTopics.map( topic => (
                    <option value={`${topic._id},${topic.topicName},${topic.totalQuestions}`}> 
                      {topic.topicName} 
                    </option>
                  ))}
                </select>
                {errors?.topicNameIdQuestions && ( <div className="text-red-500">{errors?.topicNameIdQuestions?.message}</div> )}
              </>
            )}


            </div>
            <div>
                <p className="block text-sm font-medium text-gray-700 mt-3"> Start Date </p>
                <input maxLength={800} type='date' min={DateTime.now().toFormat('yyyy-MM-dd')} {...register('startDate')}  className="w-full p-1 border-b border-gray-300 focus:outline-none focus:border-green-500" />
                {errors?.expiryDate && ( <div className="text-red-500">{errors?.startDate?.message.message} </div> )}
                {/* {errors?.expiryDate && ( <div className="text-red-500">{JSON.stringify(errors.startDate.message.message, null, 2)} </div> )} JSON.stringify to solve [object object] jsx error, null 2 is for indenting*/} 

              </div>
            <div>
                <p className="block text-sm font-medium text-gray-700 mt-3"> Expiry Date </p>
                <input maxLength={800} type='date'  {...register('expiryDate')}  className="w-full p-1 border-b border-gray-300 focus:outline-none focus:border-green-500" />
                {
                  errors.expiryDate 
                    ? (errors.expiryDate.message.message 
                      ? <div className="text-red-500"> {errors.expiryDate.message.message} </div>
                      : <div className="text-red-500"> {errors.expiryDate.message} </div>
                    )
                  : null
                }
              </div>

            <div className='flex justify-end'>
              <button type="submit" disabled={isSubmitting} className={`bg-green-500 mt-3 text-white font-semibold py-2 px-4 rounded-sm ${isSubmitting ? 'cursor-not-allowed opacity-50' : 'hover:bg-green-600'}`}>
                {action}
              </button>
              <span onClick={handleCloseModal} className='bg-gray-400 mt-3 mx-2 text-white py-2 px-4 rounded-sm hover:bg-gray-500 cursor-pointer'>
                Cancel
              </span>
            </div>
        </form>
      </div>
  );
};
 
export default AddEditNewCmpFormModal;
