import React, { useState, useEffect, useRef, useContext  } from 'react';
import { Row, CommentSection, Badge, CheckboxesTags } from '../../shared/utils';
import { toast } from 'react-toastify';
import { UploadFileInput } from '../../shared/utils';
import { useForm, Controller  } from 'react-hook-form';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import { getCmpForm, updateCmpForm, createNotification } from '../../../API/Api';
import { jwtDecode } from 'jwt-decode';
import { z } from 'zod';
import { zodResolver } from "@hookform/resolvers/zod";
import RichTextEditor from './RichTextEditor'
import { TokenContext } from '../../../App';

function FormQuestions({question, subTopicIndex, questionIndex, cmpForm, setCmpForm, setFetchCounter, allUsers}) {
  const [richTextEditorAdded, setRichTextEditorAdded] = useState(false) 
      const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(encodedAccessToken)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cmpFormId = queryParams.get('formId'); 

  // const cmpQuestionSchema = z.object({
  //   response: z.string().optional(),
  //   responseDate: z.string().optional(),
  //   fileUrl: z.string().optional(),
  //   status: z.string().optional(),
  //   comments: z.array(
  //     z.object({
  //       comment: z.string(), 
  //       commentId: z.string(),  
  //       name: z.string(),  
  //       createdAt: z.string(),  
  //     }).optional()
  //   ),
  // }) 


  // to select last known date, jsut object.keys then [0]
  const initalQuestionDataAllDates = cmpForm.subTopics[subTopicIndex].questions[questionIndex].responses

  // const firstDate = Object.keys(initalQuestionDataAllDates)[-1] || DateTime.now().toFormat('dd-MM-yyyy');
  const todayDate = DateTime.now().toFormat('dd-MM-yyyy')
  const listOfDates = Object.keys(initalQuestionDataAllDates)

  const lastDate = listOfDates.length > 0 && listOfDates[listOfDates.length - 1] !== todayDate && listOfDates[listOfDates.length - 1] !== null ? listOfDates[listOfDates.length - 1] : todayDate;
  // const firstDate = listOfDates.length > 0 && listOfDates[0] !== todayDate && listOfDates[0] !== null   ? listOfDates[0]   : todayDate;
  
  // if there is an option of today's date, show it
  // if there is no past data, show today's date
  // if there is past data, show most recent entry
  const intialQuestionDataRecentDate = initalQuestionDataAllDates[lastDate]
  // go to last option

  const [selectedDate, setselectedDate] = useState(lastDate)
 

  const { reset, register, handleSubmit, setValue, watch, control, formState: {errors, isSubmitting } } = useForm({
    defaultValues: { 
      response: intialQuestionDataRecentDate?.response || '', 
      responseDate: intialQuestionDataRecentDate?.responseDate || '',
      responsePerson: intialQuestionDataRecentDate?.responsePerson || '',
      fileUrl: intialQuestionDataRecentDate?.fileUrl || '',
      richText: intialQuestionDataRecentDate?.richText|| '',
      status: intialQuestionDataRecentDate?.status || 'pending',
      emailNotifications: intialQuestionDataRecentDate?.emailNotifications || [],
      reviewDate: intialQuestionDataRecentDate?.reviewDate || '',
      reviewPerson: intialQuestionDataRecentDate?.reviewPerson || '',
      comments:  intialQuestionDataRecentDate?.comments || []
    },
    // resolver: zodResolver(cmpQuestionSchema)
  });
  const formData = watch() 

  const renderCount = useRef(0);
  useEffect(() => { 

    if (initalQuestionDataAllDates[selectedDate]?.richText[0] == '<') { setRichTextEditorAdded(true) } // somehow rich text have issues with comparison
    if (renderCount.current === 0) { renderCount.current += 1; } // only display date change on 2nd render, not inital load.
    else { 
      toast.info(`Date changed: ` +  (question?.frequency == 'Daily' ? selectedDate.split(' to ')[0] : selectedDate.replace(/-/g,'/')))  
      reset({ 
        response: initalQuestionDataAllDates[selectedDate]?.response || '', 
        responseDate: initalQuestionDataAllDates[selectedDate]?.responseDate || '',
        responsePerson: initalQuestionDataAllDates[selectedDate]?.responsePerson || '',
        fileUrl: initalQuestionDataAllDates[selectedDate]?.fileUrl || '',
        richText: initalQuestionDataAllDates[selectedDate]?.richText|| '',
        status: initalQuestionDataAllDates[selectedDate]?.status || 'pending',
        emailNotifications: initalQuestionDataAllDates[selectedDate]?.emailNotifications || [],
        reviewDate: initalQuestionDataAllDates[selectedDate]?.reviewDate || '',
        reviewPerson: initalQuestionDataAllDates[selectedDate]?.reviewPerson || '',
        comments:  initalQuestionDataAllDates[selectedDate]?.comments || []
      });

    }
  }, [selectedDate]);


  const onSubmit = async (allData, action) => {
    if (!richTextEditorAdded) { allData.richText = '' }
    if (action !== 'pending') { allData.status = action }
    if (allData.response === '' && action !== 'pending') { 
      toast.error('Please respond before submit or review')
      return
    }


    try {
      let newestCmpForm =  await getCmpForm(cmpFormId) 
      newestCmpForm = newestCmpForm.data 

      const latestComments = newestCmpForm.subTopics[subTopicIndex].questions[questionIndex].responses[selectedDate].comments
      newestCmpForm.subTopics[subTopicIndex].questions[questionIndex].responses[selectedDate] = allData
      newestCmpForm.subTopics[subTopicIndex].questions[questionIndex].responses[selectedDate].comments = latestComments

      if (action === 'submit') { 
        newestCmpForm.subTopics[subTopicIndex].questions[questionIndex].responses[selectedDate].responsePerson = decodedToken.name
        newestCmpForm.subTopics[subTopicIndex].questions[questionIndex].responses[selectedDate].responseDate = DateTime.now().setZone('Asia/Singapore').toFormat('dd-MM-yyyy HH:mm:ss')
      }

      if (action === 'reviewed') { 
        newestCmpForm.subTopics[subTopicIndex].questions[questionIndex].responses[selectedDate].reviewPerson = decodedToken.name
        newestCmpForm.subTopics[subTopicIndex].questions[questionIndex].responses[selectedDate].reviewDate = DateTime.now().setZone('Asia/Singapore').toFormat('dd-MM-yyyy HH:mm:ss')
      }

      await updateCmpForm(newestCmpForm)
      setValue('response', allData.response)
      setFetchCounter(prev => prev + 1);

      if (action !== 'pending') {
        setValue('status', action) // to not change status when simply 'saved'  
        const fieldsToUpdate = ['responseDate', 'responsePerson', 'reviewDate', 'reviewPerson'];
        fieldsToUpdate.forEach(field => setValue(field, allData[field])); 
 
        createNotification({
          type: 'CMP',
          text: `Updated Status for ${subTopicIndex + 1}, Question ${questionIndex + 1}, of form ${newestCmpForm.formName}`,
          date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
      })
      }


      toast.success(`Updated Subtopic ${subTopicIndex + 1}, Question ${questionIndex + 1}`)
    } catch(e) {
      toast.error('Something went wrong...')
    }
  }
 
  const handleSubmitCmpFormComments = async (commentData) => {
    try { 
      cmpForm.subTopics[subTopicIndex].questions[questionIndex].responses[selectedDate].comments.push(commentData) 
      const updatedNewestCmpForm = structuredClone(cmpForm)
      await updateCmpForm(updatedNewestCmpForm)
      createNotification({
        type: 'CMP',
        text: `A new comment has been posted for ${subTopicIndex + 1}, Question ${questionIndex + 1}, of form ${cmpForm.formName}`,
        date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
    })

    } catch(e) {
      toast.error('Something went wrong...')
    }
  }

  const getStatusTag = (status) => {
    if (status == 'pending') {
        return <p className="bg-red-400 text-black mx-auto h-7 w-40 rounded-full px-2 py-1.5 text-xs text-center font-semibold mr-6"> Pending response </p> 
    }
    else if (status == 'submit') {
        return <p className="bg-yellow-200 text-black mx-auto h-7 w-40 rounded-full px-2 py-1.5 text-xs text-center font-semibold  mr-6"> Pending review</p>
    }
    else if (status == 'reviewed') {
        return <p className="bg-green-300 text-black mx-auto h-7 w-40 rounded-full px-2 py-1.5 text-xs text-center font-semibold  mr-6"> Reviewed </p> 
    }
    else {
      return <p className="bg-orange-400 text-black mx-auto h-7 w-40 rounded-full px-2 py-1 text-xs text-center font-semibold  mr-6">Edge case!</p> 
    }
  }

  let cmpDateOptions = Object.keys(cmpForm.subTopics[subTopicIndex].questions[questionIndex].responses)
  // cmpDateOptions = cmpDateOptions.filter( currentOption => currentOption !== DateTime.now().toFormat('dd-MM-yyyy') )  // remove current date to not repeat option\
  
  // const startDate = cmpForm.subTopics[subTopicIndex].questions[questionIndex].startDate
  // const formStarted = DateTime.fromFormat(startDate, "dd-MM-yyyy") <= DateTime.now();

  const formStarted = true //formStated was old removed feature where future forms cannot be filled yet

  return (
    <div className='relative'> 
      {/* {!formStarted && (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-20 pointer-events-none">
          <span className="relative text-white font-semibold top-10">Question not available yet ({startDate})</span>
        </div>
      )} */}

      <form onSubmit={handleSubmit((data) => onSubmit(data, 'submit'))} className={`border pt-3 px-3 ${formStarted ? 'bg-slate-100' : 'bg-gray-300'}`}>
          <Row className='relative py-2 bg-blue-100 p-4 -m-3.5'>
              <p className='w-8/12 whitespace-pre-wrap mr-5'><strong>Question {questionIndex+1}: </strong> {question?.question} </p>
              <div className='w-4/12'>
                {/* { !formStarted ? (
                    <select disabled={true} value={cmpForm.subTopics[subTopicIndex].questions[questionIndex].startDate} onChange={(e) => setselectedDate(e.target.value)} className="w-full h-10 p-1 font-normal border-b-2 bg-gray-300">
                      <option value={cmpForm.subTopics[subTopicIndex].questions[questionIndex].startDate}>
                        Form opens on {cmpForm.subTopics[subTopicIndex].questions[questionIndex].startDate}
                      </option>
                    </select>
                  ) : ( */}

                    <Controller name={'emailNotifications'} control={control} render={({ field }) => (
                           <CheckboxesTags field={field} allOptions={allUsers.map( user => user.email)} /> 
                    )}/>
 
                    <select value={selectedDate} onChange={(e) => setselectedDate(e.target.value)} className=" mt-2 w-full p-1 h-8 font-normal border-b-2 border-b-gray-300 bg-gray-50 mb-2 focus:outline-none focus:border-green-500">
                      {cmpDateOptions.map( (pastResponseDate, index) => (
                        <option key={pastResponseDate}  value={pastResponseDate}>
                          Period {index+1}:⠀
                          {question.frequency == 'Daily' ? pastResponseDate.split(' to ')[0] : pastResponseDate.replace(/-/g,'/')}  
                        </option>
                      ))}
                    </select>
                    
                  {getStatusTag(formData.status)}
                </div>
          </Row>
          <hr/>
          <Row className='my-3 w-full pt-3'>
            <span> <strong>Frequency: </strong> {question?.frequency.toUpperCase()} </span>
            <span> <strong>Next Renewal: </strong> {question?.nextRenewalDate} </span> 
            <span> <strong>Regulatory Reference: </strong> {question?.regulatoryReference} </span>
          </Row>
          <hr/>

            {richTextEditorAdded && formStarted && ( 
                <Controller name={'richText'} control={control} render={({ field }) => (
                    <RichTextEditor field={field}/> 
                )}
              />
              )
            }
            {/*  for REACT HOOK FORMS, use <Controller> to handle external controlled components */}


          <Row className='my-3'>
            <div className='w-5/12'>
              <strong>Checked/Completed: </strong>
              <label className={`flex items-center ml-2 my-1.5 ${formStarted && 'cursor-pointer'}`}>
                <input maxLength={800} disabled={!formStarted} value='Yes' type="radio" className="peer sr-only" {...register('response')} />
                <span className="w-2.5 h-2.5 rounded-full bg-white ring-2 ring-gray-300 ring-offset-2 ring-offset-white transition-all hover:shadow peer-checked:ring-green-500 peer-checked:bg-green-500 mr-2"></span>
                Yes
              </label>

              <label className={`flex items-center ml-2 my-1.5 ${formStarted && 'cursor-pointer'}`}>
                <input maxLength={800} disabled={!formStarted} value='No' type="radio" className="peer sr-only" {...register('response')} />
                <span className="w-2.5 h-2.5 rounded-full bg-white ring-2 ring-gray-300 ring-offset-2 ring-offset-white transition-all hover:shadow peer-checked:ring-green-500 peer-checked:bg-green-500 mr-2"></span>
                No
              </label>
                <UploadFileInput customName={'Attach Files'} azureFileName={typeof initalQuestionDataAllDates[selectedDate]?.fileUrl.split('/').pop() === 'string' ? initalQuestionDataAllDates[selectedDate]?.fileUrl.split('/').pop() : ''}
                readOnly={!formStarted} setValue={setValue} register={register} watch={watch} formName={'fileUrl'} /> 
            </div>
            
            <div className='w-6/12'>
              <CommentSection disabled={!formStarted} setValue={setValue} parentFormId={cmpForm._id} cmp={cmpForm} pastComments={cmpForm?.subTopics[subTopicIndex]?.questions[questionIndex]?.responses[selectedDate]?.comments || []} submitFormComment={handleSubmitCmpFormComments} /> 
            </div>
          </Row>

          <Row className='mt-3 mb-1 justify-end'>
          {richTextEditorAdded  && formStarted ? (
              <span className='cursor-pointer border bg-red-100 p-1 px-2' onClick={() => setRichTextEditorAdded(false)}> 
                Remove Rich Text Editor 
              </span> 
            ) : (
              <span className={`border bg-green-100 p-1 px-2 ${formStarted && 'cursor-pointer'}`} onClick={() => setRichTextEditorAdded(true)}>
                Add Rich Text Editor 
              </span> 
            )}
            
            <div>
            { decodedToken.role !== 'viewer' && (
              <>
                <button disabled={!formStarted} onClick={handleSubmit((data) => onSubmit(data, 'pending'))} className='bg-green-400 mx-4 mt-2 font-medium px-3 rounded-sm'> Save </button>
                <button disabled={!formStarted} onClick={handleSubmit((data) => onSubmit(data, 'submit'))} className='bg-green-400 mx-4 mt-2 font-medium px-3 rounded-sm'> Submit </button>
              </>
              )} 
              { decodedToken.role !== 'viewer' &&  decodedToken.role !== 'executive' && (
                <button disabled={!formStarted} onClick={handleSubmit((data) => onSubmit(data, 'reviewed'))} className='bg-green-400 mx-4 mt-2 text- font-medium px-3 rounded-sm'> Reviewed </button>
              )} 
            </div>
          </Row>

          <Row className='mb-4 justify-end'> 
              <div> Submitted by: {formData.responsePerson} {formData.responsePerson && "on"} {formData.responseDate} </div>
              {/* <div> Submitted by: {allValues.responsePerson} {allValues.responsePerson && "on"} {allValues.responseDate} </div> */}
              <div> Reviewed by: {formData.reviewPerson} {formData.reviewPerson && 'on'} {formData.reviewDate} </div>
              {/* <div> Reviewed by: {allValues.reviewPerson} {allValues.reviewPerson && 'on'} {allValues.reviewDate} </div> */}
          </Row>
        </form>
      </div>
  );
}

export default FormQuestions
