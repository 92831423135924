import React from 'react';
import { Row } from '../../shared/utils';
import { useFieldArray, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';


function MonitoringSubTopicCard({watch, register, nestIndex, control, removeSubTopic}) { 

  const { fields, append, remove } = useFieldArray({
    control,
    name: `subTopics.${nestIndex}.questions`
  });

  const numberOfQuestions = watch(`subTopics.${nestIndex}.questions`).length + 1;

  const questionBody = (numberOfQuestions) => ({
    question: '', 
    questionId: uuidv4(), 
    frequency: 'Daily', 
    nextRenewalDate: DateTime.now().toFormat('dd-MM-yyyy'), 
    regulatoryReference: '', 
    internalReference: `Q-${numberOfQuestions}-S-${nestIndex + 1}-D-${DateTime.now().toFormat('dd-MM-yyyy')}`,
    responses: { }  // 'mongoDB converts empty object to array hence this placeholder'
    // { response, responseDate, fileUrl, status, richText, comments[] }
    // comments: [ {comment, commentId, name, createdAt } ]
  })




  return (
    <div className="rounded-sm p-4 my-4 mx-2 border bg-gray-50">
      <Row>
        <div className='w-9/12'>
          <strong className='underline'> SubTopic {nestIndex + 1}: </strong>  
          <input maxLength={800} type="text" placeholder='Enter Sub Topic'{...register(`subTopics.${nestIndex}.subTopicName`)} className="w-8/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
        </div>
        <span onClick={() => removeSubTopic(nestIndex)} className='bg-red-400 border p-1 px-2 mb-5 cursor-pointer'> Delete SubTopic</span>
      </Row>
 

      <table className="w-full border-collapse my-4 text-sm">
        <thead>
          <tr className='text-left'>
            <th className='w-5/12'> Questions:</th>
            <th className='w-1/12'> Frequency</th>
            <th className='w-1/12 text-center'> Next Renewal </th>  
            <th className='w-3/12 text-center'> Regulatory Reference </th>
            <th className='w-2/12 text-center'> Internal Reference </th> 
          </tr>
        </thead>

        <tbody>
        {
        fields.map( (field, qnIndex) => (
        <tr key={field.id}>
          <td className='pr-2'> 
            <textarea maxLength={800} {...register(`subTopics.${nestIndex}.questions.${qnIndex}.question`)} placeholder='Enter Question' className="w-full p-1 my-1 bg-gray-100 border-b border-gray-300 rounded focus:outline-none focus:border-green-500" /> 
          </td>
          <td className='pr-2'> 
            <select {...register(`subTopics.${nestIndex}.questions.${qnIndex}.frequency`)} className="w-full p-1 border-b border-gray-300 bg-gray-100 rounded focus:outline-none focus:border-green-500">
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Annually">Annually</option>
              <option value="N/a">N/a</option>  {/* NA means never refresh, question answer once only  */}
            </select>
          </td>

          <td>
            <input maxLength={800} type='date' min={DateTime.now().toFormat('yyyy-MM-dd')}  {...register(`subTopics.${nestIndex}.questions.${qnIndex}.nextRenewalDate`)}  className="w-full p-1 border-b bg-gray-100 focus:outline-none focus:border-green-500 " />
          </td>

          <td className='pr-2'> 
            <input maxLength={800} {...register(`subTopics.${nestIndex}.questions.${qnIndex}.regulatoryReference`)} placeholder='Enter Regulatory Reference' className="focus:outline-none focus:border-green-500 w-full p-1 border-b border-gray-300 rounded bg-gray-100"/> 
          </td>
          <td className='pr-2'> 
            <input maxLength={800} disabled={true} {...register(`subTopics.${nestIndex}.questions.${qnIndex}.internalReference`)} className="w-full p-1 border-b  rounded-sm"/>
            {/* value={'Q-(1)-S-(1)-T-2024-07-11'} */}
          </td>

          <td> 
            <span onClick={() => remove(nestIndex)}  className="bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded cursor-pointer"> X </span> 
          </td>
        </tr>
        ))
        }
        </tbody>
        <span onClick={() => append(questionBody(numberOfQuestions))} className=' hover:bg-gray-400 border-2 border-gray-400 bg-gray-200 mt-3 p-1 px-2 cursor-pointer'>
          Add Question
        </span>
      </table>



    </div>
  );
}

export default MonitoringSubTopicCard;
 
