import React from 'react'
import { getAllNotifications } from '../../../API/Api'
import { jwtDecode } from "jwt-decode";
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

export const KycAmlNotifications = () => {
  const token = 'old jwt'

  const fetchNotifications = async (token) => {
    const res = await getAllNotifications("KYC");
    const currentDate = DateTime.now();
  
    return res.data.map(item => {
      const itemDate = DateTime.fromFormat(item.date, "dd-MM-yyyy HH:mm:ss");
      const timeDifference = currentDate.diff(itemDate, ['years', 'months', 'days']);
      
      let timeAgo;
      if (timeDifference.years >= 1) {
        timeAgo = `${Math.floor(timeDifference.years)} year(s) ago`;
      } else if (timeDifference.months >= 1) {
        timeAgo = `${Math.floor(timeDifference.months)} month(s) ago`;
      } else {
        timeAgo = `${Math.floor(timeDifference.days)} day(s) ago`;
      }
      
      return { text: item.text, timeAgo };
    });
  };


  const { data: smallCards, isPending, error } = useQuery({
    queryKey: ['notifications', token], // queryKey acts as a cache check, so if a query w/ same keyname alr exist it wont fetch again
    queryFn: () => fetchNotifications(),
  } );

  if (isPending) return <div>Loading...</div>;
  if (error) return <div>Error fetching data</div>;


  return (
        <div>
          {smallCards.map((card, index) => (
            <div key={index} className="bg-gray-300 p-2 mb-2 flex justify-between">
              <div>{card.text}</div>
              <div className="text-sm text-gray-500 pl-2">{card.timeAgo}</div>
            </div>
          ))}
        </div>
  )
}

export default KycAmlNotifications