import React, {useState, useEffect} from 'react';
import { BsChat } from "react-icons/bs";


function QuestionCard({handleInputChange, handleSubmitComment, disabled, automated=false, questionNumber, question, inputName, justification, value }) {

  let borderColorClass = "border";
  const SDD_Questions = ['singaporeGovernmentEntity', 'listedOnStockExchange', 'singaporeFI', 'nonSingaporeFI', 'investmentVehicle', 'customerPensionSuperannuation']
  const EDD_Questions = ['politicallyExposedPerson', 'countryCountermeasureFATF', 'countryInadequateAML_CFT', 'unverifiedBusinessActivity', 'unverifiedCorporateStructure', 'unverifiedFinancialActivity']
  const AA_Questions = ['unusualOwnershipStructure', 'unusualBusinessCircumstances', 'nomineeShareholdersBearer', 'anonymousTranscations', 'unknownPaymentSources']
  const AA_automated_Questions = ['higherRiskSector', 'highRiskJurisdictionFATF', 'credibleBodyIdentifiedCountry' ]

  const [originalResponse, setOriginalResponse ] = useState(value || value?.response) 

  // useEffect(() => {
  //   setOriginalResponse(value || value?.response);
  // }, [value]);
  // this re render current component

  if ((value == 'Yes' || value?.response == 'Yes') && SDD_Questions.includes(inputName) ) {
    borderColorClass = "border border-green-500";
  } else if (
    (value == 'Yes' || value?.response == 'Yes') && 
    (EDD_Questions.includes(inputName) ||  
    AA_automated_Questions.includes(inputName) ||
    (AA_Questions.includes(inputName) && value?.justification.trim() === '')
    )
  ) 
    {
    borderColorClass = "border border-red-500"
  } else if ((value == 'Yes' || value?.response == 'Yes') && (value?.justification?.trim() !== '')) {
    borderColorClass = "border border-orange-300";
  }


  return (
    <div className={`bg-white rounded-sm p-4 mb-4 mx-2 border ${borderColorClass}`}>
      <strong>Question {questionNumber}: </strong> {question}
      <div>
        <p className="my-2">
          {/* if answer has been changed, remove automatic.  */}
          { automated && (value == originalResponse || value?.response == originalResponse) && 
            <label className='white rounded-full px-2 py-1 mr-2 text-xs font-semibold bg-green-200'>
              Automated
            </label> 
          }

          <strong>Response: </strong>
        </p>
        <div className="flex justify-between">
          <div>
            <input maxLength={800} disabled={disabled}  type="radio" value="Yes" name={inputName} onChange={(e) => handleInputChange(e, 'response')}  checked={value == 'Yes' || value?.response == 'Yes'}/> 
            <span className='m-1'> Yes </span> 
            
            <input maxLength={800} disabled={disabled}  type="radio" value="No" id={`${inputName} + No`} name={inputName} onChange={(e) => handleInputChange(e, 'response')}  checked={value == 'No' || value?.response == 'No'}/> 
            <span className='m-1'> No </span>
          </div>

          {justification && value?.response == 'Yes' &&  (

          <div className='w-10/12'>
            <div className='flex '>
          {/* {justification && value?.response == 'Yes' &&  ( */}

              <p className="font-semibold mb-1 w-full"> 
                Justification: <br/>
                <textarea maxLength={800} disabled={disabled} rows={3} value={value?.justification} placeholder={`Justify your response\n`} name={inputName} onChange={(e) => handleInputChange(e, 'justification')}  className="border p-1.5 font-normal h-11/12 w-11/12"></textarea>
              </p> 

              <p className="font-semibold mb-1 w-full"> 
                Comments: 
                {value.comments.map( (comment) => (
                  <div className='my-1 p-1.5 border'>
                    <p className='text-xs underline font-normal flex justify-between'>
                        <span> {comment.name}: </span> {comment.commented_at}
                    </p>
                    <p className='w-full text-sm break-words'>
                      {comment.content}
                    </p>
                  </div>
                ))} 
                <div className="flex">
                  <input maxLength={800} disabled={disabled}  placeholder="Write comment" name={inputName} value={value?.newComment} onChange={(e) => handleInputChange(e, 'newComment')} className="w-11/12 p-1 font-normal border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500"/>
                  <button onClick={(e) => handleSubmitComment(e, 'newComment', inputName)} >
                    <svg width="34px" height="34px" viewBox="-6.24 -6.24 36.48 36.48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
                    <rect x="-6.24" y="-6.24" width="36.48" height="36.48" rx="18.24" fill="#25e44b" strokeWidth="0"/> </g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.288"/>
                    <g id="SVGRepo_iconCarrier"> <path d="M3 7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2Z" stroke="#f7f7f7" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round"/> </g>
                    </svg>
                  </button>
                </div>
              </p>
            </div>
          </div>
          )}

          
        </div>
      </div>
    </div>
  );
}

export default QuestionCard;



//  <input maxLength={800} disabled={disabled}  checked={SDD["6.12"]} style={{ accentdivor: 'green', width: 18, height: 18, marginLeft: '10px', marginRight: '4px' }} 
// type="radio" name="govtEntity" onClick={() => {setComplianceResults({ SDD: { ...SDD, ["6.12"]: true } }) }} /> Yes 
//  <input maxLength={800} disabled={disabled}  checked={!SDD["6.12"]} style={{ accentdivor: 'green', width: 18, height: 18, marginLeft: '10px', marginRight: '4px' }} 
// type="radio" name="govtEntity" onClick={() => setComplianceResults({ SDD: { ...SDD, ["6.12"]: false } })} /> No 