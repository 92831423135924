import React, {useEffect} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { getUserById , loginUser, updatePassword } from '../../../API/Api';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Tooltip } from '@mui/material';


const UserProfile = () => { 
  
  const passwordChangeSchema = z.object({
    currentPassword: z.string().min(2),
    newPassword: z.string().min(8),
    confirmPassword: z.string().min(8)
  })
  .refine((data) => /[a-z]/.test(data.newPassword), { message: "New password must contain at least one lowercase letter (a-z)", path: ["confirmPassword"] })
  .refine((data) => /[A-Z]/.test(data.newPassword), { message: "New password must contain at least one uppercase letter (A-Z)", path: ["confirmPassword"] })
  .refine((data) => /\d/.test(data.newPassword), { message: "New password must contain at least one digit (0-9)", path: ["confirmPassword"] })
  .refine((data) => /[\W_]/.test(data.newPassword), { message: "New password must contain at least one special character", path: ["confirmPassword"] })
  .refine((data) => data.newPassword === data.confirmPassword, { message: "New password and confirm password must match", path: ["confirmPassword"] })  

  const { register, handleSubmit, setError, reset, formState: {errors, isSubmitting} } = useForm({
    defaultValues: {currentPassword: '', newPassword: '', confirmPassword: '' },
    resolver: zodResolver(passwordChangeSchema)
  }); 

  
  const onSubmit = async (passwordData) => {
    try { 
      const result = await updatePassword(passwordData)
      reset();

      if (result.data.status) {
        toast.success('Password successfully updated!')
      } else {
        toast.error('Current Password incorrect')
      } 

    } catch(e) {
      toast.error('Something went wrong...')
    }
  };

  const onError = async () => {
    reset()
  };
  



  const inputClass = "w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500";
  const labelClass = "block text-sm font-medium text-gray-700";

  return (
    <>
        <div className="w-11/12">
          <div>
            <h2 className="text-xl font-semibold mb-4">Change Password</h2>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="mb-4">
                <label className={labelClass}>Current Password</label>
                <input maxLength={800} type="password" {...register('currentPassword')} className={inputClass} />
              </div>
              {errors.currentPassword && ( <div className="text-red-500">{errors.currentPassword.message}</div> )}

              <div className="mb-4">
                <label className={labelClass}>New Password</label>
                <input maxLength={800} type="password" {...register('newPassword')} className={inputClass} />
              </div>

              <div className="mb-4">
                <label className={labelClass}>Confirm Password</label>
                <input maxLength={800} type="password" {...register('confirmPassword')} className={inputClass} />
                <Tooltip title={
                    <p style={{ fontSize: "14px" }}>
                        • At least 8 characters <br/>
                        • At least 1 lowercase (a-z) <br/>
                        • At least 1 uppercase (A-Z) <br/>
                        • At least one digit (0-9) <br/>
                        • At least 1 special character (punctuation)  <br/>
                    </p>} 
                    arrow>
                  <span className='text-xl' > ⓘ </span>
                </Tooltip>
              </div>
              {errors.confirmPassword && ( <div className="text-red-500">{errors.confirmPassword.message}</div> )}
              
              <div className="mt-6">
                <button type="submit" disabled={isSubmitting} className={`bg-green-500 text-white font-semibold py-2 px-4 rounded-md transition-all duration-300 ${isSubmitting ? 'opacity-50 cursor-not-allowed': 'hover:bg-green-600' }`}>
                   {isSubmitting ? 'Updating...' : 'Update Password'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
  );
};

export default UserProfile;



function resetForm() {

}