import React, {useEffect, useState, useContext} from 'react';
import { useLocation } from 'react-router-dom';
import { getkyc  } from "../../../API/Api"
import _IndividualDetails from './_IndividualDetails'
import _EntityDetails from './_EntityDetails'
import _RiskAssessment from './_RiskAssessment_MAS'
import FormStatusEnum from '../../../data/FormStatusEnum';
import PageNotFound from '../../../PageNotFound'
import OnGoingMonitoring from './OnGoingMonitoring';
import FormSteps from './FormSteps'
import { ToastContainer } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { TokenContext } from '../../../App';

const __KycAmlForm = () => {
  const [encodedAccessToken] = useContext(TokenContext) 
  const location = useLocation();

  const currentUrl = location.pathname
  const queryParams = new URLSearchParams(location.search);
  const token = encodedAccessToken || queryParams.get('formToken') 
  const clientFormId = queryParams.get('clientFormId') 
  const currentPage = queryParams.get('page'); 
  const decodedToken = jwtDecode(token)
 
  const companyId = decodedToken?.companyId; 

  const [clientData, setclientData] = useState({name: '', type: '', formStatus: '', maxPage:'3'}) // maxPage 3 initial value to not show 'please complete previous steps' udirng inital load
  const [formCurrentPage, setformCurrentPage] = useState(currentPage)

  useEffect( () => {
    const fetchData = async () => { 
      const kycData = await getkyc(clientFormId, token) 
      setclientData(kycData.data)
    }
    fetchData()
      }, 
  [formCurrentPage]);

    let showSubmitButton = true;
    let readOnlyOne = false;
    let readOnlyTwo = false; 
    if (clientData.maxPage > 1 || decodedToken.role == 'viewer') { 
      // readOnlyOne=true 
      showSubmitButton = false;
    }
    if (clientData.maxPage > 2 || decodedToken.role == 'viewer') { 
      readOnlyOne=true
      readOnlyTwo=true
     }
  


    if (!clientData) { 
      console.log('page not found1')
      return <PageNotFound /> 
    } 
     
    // if encoded formToken userId matches the user form, then show.
    if (currentUrl === '/form/kyc-aml' ) {
    // if (currentUrl === '/form/kyc-aml' && (!decodedToken?.email )) {

      return (
      <div className='flex flex-col items-center justify-center min-h-screen w-full bg-slate-300 absolute top-0 left-0 right-0 z-50'>

        <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} limit={3} autoClose={2750}/>
        {clientData.type === 'individual'  && <_IndividualDetails token={token} showSubmitButton={showSubmitButton} currentUrl={currentUrl}  individualName={clientData.name} clientFormId={clientFormId} companyId={companyId} readOnly={readOnlyOne} maxPage={clientData.maxPage}/>}
        {clientData.type === 'entity' && <_EntityDetails token={token} showSubmitButton={showSubmitButton}  currentUrl={currentUrl}  companyName={clientData.name} clientFormId={clientFormId} companyId={companyId} readOnly={readOnlyOne} maxPage={clientData.maxPage}/>} 
      </div>
      //External link form
  )}
  //  What is this case for? 
  //  else if (currentUrl === '/form/kyc-aml' && !decodedToken?.email) {
  //   console.log('page not found2')
  //   return <PageNotFound /> 
  // }


  // Internal Link Form
  if (currentPage == 1 && clientData.maxPage >= 1) {
    return <>
      <FormSteps/>
      {clientData.type === 'individual'  && <_IndividualDetails token={token} showSubmitButton={showSubmitButton} currentUrl={currentUrl} individualName={clientData.name} clientFormId={clientFormId} readOnly={readOnlyOne}/>}
      {clientData.type === 'entity' && <_EntityDetails token={token} showSubmitButton={showSubmitButton} currentUrl={currentUrl} companyName={clientData.name} clientFormId={clientFormId} readOnly={readOnlyOne}/>} 
      </>
  } else if (currentPage == 2 && clientData.maxPage >= 2) {
    return <>
      <FormSteps/>
      <_RiskAssessment  currentUrl={currentUrl} clientFormId={clientFormId} userType={clientData.type} readOnly={readOnlyTwo} /> 
      </>
  } else if (currentPage == 3 && clientData.maxPage >= 3) {
    return <>
      <FormSteps/>
      <OnGoingMonitoring  currentUrl={currentUrl} clientFormId={clientFormId} userType={clientData.type} />  
      </>
  }  else {
    return <>
      <FormSteps />
      <div>
        Please complete previous form steps!
      </div>
    </>
  }
};

export default __KycAmlForm;
