import React, {useContext} from 'react'
import UserChangeProfile from './UserChangeProfile'
import UserChangePassword from './UserChangePassword'
import CompanyProfile from './CompanyProfile'
import { Card } from '../utils'
import { jwtDecode } from 'jwt-decode'
import { TokenContext } from '../../../App'


const Profile = () => {
      const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(encodedAccessToken)

  return ( 
    <div>
      <Card>
        <h1 className="text-2xl font-bold mb-6">User Profile</h1>
        <div className='flex' >
          <UserChangeProfile />
          <UserChangePassword />
        </div>
      </Card>

      {decodedToken.role == 'admin' && 
        <CompanyProfile/>}
    </div>
  )
}

export default Profile
