import React from 'react';
import FormStatusChart from './FormStatusChart';
import OutStandingQuestionsChart from './OutStandingQuestionsChart';
import Notifications from './Notifications';
import { RightContainer, Card, Row } from "../../shared/utils"


const _ComplianceDashboard = () => {

  return (
    <div>  
        <Card>
          <h2 className="text-lg font-semibold">KYC/AML Dashboard</h2>
        </Card>

        <Card className="overflow-auto h-80">
            <h2 className="text-2xl font-semibold text-gray-700 -m-4 mb-4 p-3 bg-gray-300">Notifications</h2>
            <Notifications />
        </Card>

        <Row>   
          <Card className='w-5/12 h-fit'> 
            <h1 className="text-2xl font-semibold text-gray-700 -m-4 mb-4 p-3 bg-gray-300">Status Of Forms</h1>
            <OutStandingQuestionsChart />
          </Card>
          <Card className='w-7/12 h-fit'> 
            <h1 className="text-2xl font-semibold text-gray-700 -m-4 mb-4 p-3 bg-gray-300">Outstanding Questions</h1>
            <FormStatusChart />
          </Card>
        </Row>
    </div>
  );
};

export default _ComplianceDashboard;
