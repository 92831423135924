import React, {useState, useContext, useEffect} from 'react';
import { loginUser, verifyOtp } from '../../API/Api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import iCompass_icon from '../../images/iCompass_icon.png'
import { TokenContext } from '../../App';
import Modal from "../shared/Modal";

function Login({setIsLoggedIn}) {
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const [formStage, setFormStage ] = useState('login') //login, verify-otp
  const [error, setError] = useState(false)
  const [wrongCode, setWrongCode ] = useState(false)
  const [formData, setFormData] = useState({  
    email: '', password: '', otp: '' 
  })

  let [ecodedAccessToken, setEncodedAccessToken] = useContext(TokenContext) 
  const [showOverlay, setShowOverlay] = useState(false);
 
  
  const handleSignIn = async (e) => {
    e.preventDefault()
    setError(false)
    let resultUser
    try {  
      resultUser = await loginUser(formData)
    } catch(e) { 
      console.log("error logging in") 
    }

    if (!resultUser?.data?.status === true) {
      setFormData({ email: '', password: '', otp: ''}) //  email: '', password: '', otp: ''
      setError(true)
      return
    }
    const userRole = resultUser.data?.role

    if (userRole === 'superAdmin') {
      window.location.href = '/super-admin'
    } 
    else if (['superAdmin', 'admin', 'manager'].includes(userRole)) {
      setFormStage('verify-otp')
    } 
    else {
      const accessToken = resultUser.data.accessToken

      setEncodedAccessToken( accessToken )
      setIsLoggedIn( accessToken ) // true if token exist 
      navigate('/home')
    }
  }

  const handleVerifyOTP = async (e) => { 
    e.preventDefault()
    setWrongCode(false)
    const result = await verifyOtp(formData)  


    if (result?.data?.otpMatch === true) { 
      const accessToken = result?.data?.accessToken
      const refreshToken = result?.data?.refreshToken

      setEncodedAccessToken( accessToken )
      setIsLoggedIn( accessToken ) // true if token exist 
      navigate('/home')

    } else {
      setWrongCode(true)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {  // instead of setState, prevData => {...prevData, new: newValue}
      ...formData,         // do this, to ensure is kept up to date
      [name] : value  
    }
    setFormData(newFormData) 
  }


  return (
    <div className='relative w-full h-screen bg-zinc-800/90'> 
      <div className='flex justify-center items-center h-full'>
        <div className='max-w-[400px] w-full mx-auto bg-white p-8'>
          <h2 className='text-4xl font-bold text-center py-4 cursor-pointer'>
            <img src={iCompass_icon} className='m-auto w-3/4' onClick={() => setShowOverlay(true)}/> 
          </h2>
 

          <UpdateOverLay showOverlay={showOverlay} setShowOverlay={setShowOverlay} />

          {/* Step1, username, pw  */}
          {formStage === 'login' && ( 
            <form onSubmit={handleSignIn} className='max-w-[400px] w-full mx-auto bg-white'> 
                <div className='flex flex-col mb-4'>
                    <label>Username</label>
                    <input maxLength={800} onChange={handleInputChange} autoComplete="off" name='email' className='border relative bg-gray-100 p-2' type="text" placeholder='Email address'   value={formData.email}/>
                </div>
                <div className='flex flex-col '>
                    <label>Password</label>
                    <input maxLength={800} onChange={handleInputChange} name='password' className='border relative bg-gray-100 p-2' type="password" placeholder='Password' value={formData.password} />
                </div>
                <input maxLength={800} type='submit' value='Sign In' className='cursor-pointer w-full py-3 mt-8 bg-green-600 hover:bg-green-500 relative text-white'/>
            
                <Link to='/reset-password' replace={currentLocation.pathname === '/reset-password '}>
                  <button className='w-full text-sm cursor-pointer p-2 mt-5 bg-blue-600 hover:bg-blue-500 relative text-white'>Forget Password?</button>
                </Link>

                {error && (
                  <p className="max-w-sm rounded overflow-hidden p-4 bg-red-200 text-gray-700 my-4 text-center"> 
                    User not found with this email <br/> or Incorrect Password
                  </p> 
                )}
              </form>
            )}


            {/* Step2, verify code | initial password registration page */}
            {formStage === 'verify-otp' && (
            <form onSubmit={handleVerifyOTP} > 
                <div>
                  <div className='flex flex-col mb-4'>
                    <label className='text-2xl text-center pb-5'> Verify Code</label>
                    <input maxLength={800} name='otp' autoComplete="off" className='border relative bg-gray-100 p-2' type="text" placeholder='Enter code recieved in email' onChange={handleInputChange}/>
                    {wrongCode && <div className="max-w-sm rounded overflow-hidden bg-red-100 text-center mt-5 p-2"> Incorrect Code </div>}
                  </div>

                  <div className="max-w-sm rounded overflow-hidden bg-green-100 mb-4 px-5">
                    <p className="text-gray-700 py-4 text-center"> Code to login has been sent to your email </p>
                  </div> 
                  <button onClick={handleVerifyOTP} className='w-full py-3 mb-5 bg-green-500 hover:bg-green-600 relative text-white'>Verify Code</button>
                </div>
              </form>
            )}
        </div>
      </div>
 
    </div>
  );
}

export default Login;





function UpdateOverLay({showOverlay, setShowOverlay}) { 

  useEffect(() => {
    const isHidden = localStorage.getItem("hideOverlay");
    if (!isHidden) {
      setShowOverlay(true);
    }
  }, []);

  const handleDontShowAgain = () => {
    localStorage.setItem("hideOverlay", "true");
    setShowOverlay(false);
  };

  const handleClose = () => {
    setShowOverlay(false);
  };

  if (!showOverlay) return null;

  return (
    <div className="  fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center space-y-4 w-8/12">
        <h2 className="text-xl font-bold">Security Updates:</h2>

        <p> • Email MFA now required to login for roles 'Admin' or 'Manager'. </p>
        <p> • In the event of more than 4 failed login attempts, the account will be locked out for 20 minutes before a valid login attempt can be made again. (However the error message shown will be the same generic 'Incorrect username/password'). </p>
        <p> • Login screen no longer provides autocomplete for email field. </p>


        <div className="flex justify-center gap-4">
          <button
            onClick={handleDontShowAgain}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
          >
            Don't Show Again
          </button>
          <button
            onClick={handleClose}
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-400 transition"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};