import React, { useEffect, useContext } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { getAllUsers, getUserById , updateUser, updateUserPassword } from '../../../API/Api';
import { Card } from '../utils';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { TokenContext } from '../../../App';

let originalEmail; //need declare here global else within component functino declare cant reach another function? ssee javascript function scopes

const UserChangeProfile = () => {
      const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(encodedAccessToken)

  const userDetailsSchema = z.object({
    name: z.string().min(2),
    email: z.string().email(),
  });

  const { register, handleSubmit, setError, reset, formState: {errors, isSubmitting} } = useForm({
    defaultValues: { name: decodedToken.name, email: decodedToken.email },
    resolver: zodResolver(userDetailsSchema)
  });
  

  useEffect(() => {
    const fetchData = async () => {
      const userDetails = (await getUserById(decodedToken._id)).data
      originalEmail = userDetails.email 
      reset(userDetails);
    };

    fetchData();
  }, [reset]);


  const onSubmit = async (userData) => {
    let allEmails = await getAllUsers()
    
    const isEmailTaken = allEmails.data
      .filter(user => user.email !== originalEmail)  // Exclude current email
      .some(user => user.email === userData.email);  // Check if new email exists

    if (isEmailTaken) {
      toast.error('This email is already taken.')
    } else {
      try {
        updateUser(userData)
        toast.success('Profile successfully updated!')
      } catch(e) {
        toast.error('Something went wrong...')
      }
    };
  }

  const inputClass = "w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500";
  const labelClass = "block text-sm font-medium text-gray-700";

  // input email, how do i include the suffix @inspro.com.sg to be included as part of the value, but not allow user to change it react hookform useController
  // enter that prompt into claude.
  // because if suffix @'inspro'.com.sg is changed, the database mapping is gone.
  // for now leave it as is.

  return (
    <>
        <div className="w-11/12">
          <div>
            <h2 className="text-xl font-semibold mb-4">Personal Details</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label className={labelClass}>Name</label>
                <input maxLength={800} type="text" {...register('name')} className={inputClass} />
              </div>
              <div className="mb-4">
                <label className={labelClass}>Email</label>
                <input maxLength={800} type="email" {...register('email')} className={inputClass} />
              </div>
              <div className="mt-6">
                <button type="submit" disabled={isSubmitting} className={`bg-green-500 text-white font-semibold py-2 px-4 rounded-md transition-all duration-300 ${isSubmitting ? 'opacity-50 cursor-not-allowed': 'hover:bg-green-600' }`}>
                   {isSubmitting ? 'Updating...' : 'Update Profile'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
  );
};

export default UserChangeProfile;