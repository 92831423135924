import React, { useEffect, useState,useContext } from 'react'
import { getkycAll } from '../../../API/Api'
import { Card } from "../../shared/utils" 
import { DateTime } from "luxon";
import { jwtDecode } from "jwt-decode";
import { useQuery } from '@tanstack/react-query';

import { TokenContext } from '../../../App';

const StatisticsMonitoring = () => {
  const [ statistics, setStatistics ] = useState({
    ongoingMonitoringCount: '',
    activeFormsCount: '',
    closestRefreshDate: '',
    averageActiveDays: '',
    averageCompletionDays: '',
    daysUntilRefresh : ''
  })
      const [encodedAccessToken] = useContext(TokenContext) 
    const decodedToken = jwtDecode(encodedAccessToken)
   
  useEffect(() => {
    const fetchData = async () => {
      const companyId = decodedToken.companyId

      // TODO, move this processing logic to backend instead
      getkycAll(companyId).then((res) => {
        let ongoingMonitoringCount = 0;
        let activeFormsCount = 0;
        let totalActiveDays = 0;
        let totalActiveForms = 0;
        let totalCompletionDays = 0;
        let totalApprovedForms = 0;

        res.data.forEach((form) => {
            const status = form.status;

            if (status === 'accepted') {
                ongoingMonitoringCount++;

                // const approveTime = moment(form.updatedDate);
                // const submissionDate = moment(form.submitedDate);
                // const daysCompletion = approveTime.diff(submissionDate, 'days');
                const approveTime = DateTime.fromISO(form.updatedDate);
                const submissionDate = DateTime.fromISO(form.submitedDate);
                const daysCompletion = approveTime.diff(submissionDate, 'days').days;

                totalCompletionDays += daysCompletion;
                totalApprovedForms++;

            }
            if (status !== 'rejected' && status !== 'accepted' && status !== 'archieved') {
                activeFormsCount++;
                if (form.submitedDate) {
                    const currentDate = DateTime.now()
                    const submissionDate = DateTime.fromISO(form.submitedDate);
                    const daysActive = currentDate.diff(submissionDate, 'days').days;
                    // const currentDate = moment();
                    // const submissionDate = moment(form.submitedDate);
                    // const daysActive = currentDate.diff(submissionDate, 'days');
                    totalActiveDays += daysActive;
                    totalActiveForms++;
                }
            }
        });
        const averageActiveDays = totalActiveForms > 0 ? totalActiveDays / totalActiveForms : 0;
        const averageCompletionDays = totalApprovedForms > 0 ? totalCompletionDays / totalApprovedForms : 0;
        const refreshDates = res.data.map(form => form.nextRefreshDate).filter(date => date);

        if (refreshDates.length > 0) {
            // const currentDate = moment();
            const currentDate = DateTime.now()

            const { closestDate, daysUntilRefresh } = refreshDates
            .filter(date => DateTime.fromISO(date) > currentDate)
            .reduce((acc, date) => {
                const dateMoment = DateTime.fromISO(date);
                const daysUntilDate = dateMoment.diff(currentDate, 'days').days;
            
            // const { closestDate, daysUntilRefresh } = refreshDates
            //     .filter(date => moment(date).isAfter(currentDate))
            //     .reduce((acc, date) => {
            //         const dateMoment = moment(date);
            //         const daysUntilDate = dateMoment.diff(currentDate, 'days');

                    if (daysUntilDate < acc.daysUntilRefresh) {
                        return { closestDate: dateMoment, daysUntilRefresh: daysUntilDate };
                    }

                    return acc;
                }, { closestDate: null, daysUntilRefresh: Infinity });

            setStatistics({ ongoingMonitoringCount, activeFormsCount, averageActiveDays, averageCompletionDays, closestRefreshDate: closestDate.format('YYYY-MM-DD'), daysUntilRefresh });
        } else {
            setStatistics({ ongoingMonitoringCount, activeFormsCount, averageActiveDays, averageCompletionDays });
        }
    })}
    fetchData()
  }, []);

  return (
  <div className='w-2/5'>
      <Card className="text-sm mb-5">
        Current FY: 
        {/* <span className='text-green-600'> {statistics.ongoingMonitoringCount} </span> SAMPLE*/}
        <span className='text-green-600'> 2024 </span>
      </Card>

      <Card className="text-sm mb-5">
        Number of Employees: 
        {/* <span className='text-green-600'> {statistics.activeFormsCount} </span> SAMPLE */}
        <span className='text-green-600'> 7 </span> 
      </Card>

      <Card className="text-sm mb-5">
        Number of Overdue: 
        {/* <span className='text-green-600'> {statistics.closestRefreshDate} ({statistics.daysUntilRefresh} days) </span> SAMPLE */}
        <span className='text-green-600'> 5 </span>  
      </Card>
 
      <Card className="text-sm mb-5">
        Average Completion Days: 
        {/* <span className='text-green-600'> {statistics.averageCompletionDays} day(s) </span> SAMPLE */}
        <span className='text-green-600'> {statistics.averageCompletionDays} day(s) </span> 
      </Card> 
  </div>
  )

}

export default StatisticsMonitoring